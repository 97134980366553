body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiButtonBase-root
  .MuiButton-root
  .MuiButton-text
  .MuiButton-textPrimary
  .MuiButton-sizeMedium
  .MuiButton-textSizeMedium
  .css-1q39md6-MuiButtonBase-root-MuiButton-root {
  color: green;
}
